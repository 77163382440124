// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/luci/tree_status/proto/v1/tree_status.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../google/protobuf/timestamp.pb";

export const protobufPackage = "luci.tree_status.v1";

/** GeneralState are the possible states for a tree to be in. */
export enum GeneralState {
  /**
   * GENERAL_STATE_UNSPECIFIED - GeneralState was not specified.
   * This should not be used, it is the default value for an unset field.
   */
  GENERAL_STATE_UNSPECIFIED = 0,
  /** OPEN - The tree is open and accepting new commits. */
  OPEN = 1,
  /** CLOSED - The tree is closed, no new commits are currently being accepted. */
  CLOSED = 2,
  /**
   * THROTTLED - The tree is throttled.  The meaning of this state can vary by project,
   * but generally it is between the open and closed states.
   */
  THROTTLED = 3,
  /**
   * MAINTENANCE - The tree is in maintenance.  Generally CLs will not be accepted while the
   * tree is in this state.
   */
  MAINTENANCE = 4,
}

export function generalStateFromJSON(object: any): GeneralState {
  switch (object) {
    case 0:
    case "GENERAL_STATE_UNSPECIFIED":
      return GeneralState.GENERAL_STATE_UNSPECIFIED;
    case 1:
    case "OPEN":
      return GeneralState.OPEN;
    case 2:
    case "CLOSED":
      return GeneralState.CLOSED;
    case 3:
    case "THROTTLED":
      return GeneralState.THROTTLED;
    case 4:
    case "MAINTENANCE":
      return GeneralState.MAINTENANCE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GeneralState");
  }
}

export function generalStateToJSON(object: GeneralState): string {
  switch (object) {
    case GeneralState.GENERAL_STATE_UNSPECIFIED:
      return "GENERAL_STATE_UNSPECIFIED";
    case GeneralState.OPEN:
      return "OPEN";
    case GeneralState.CLOSED:
      return "CLOSED";
    case GeneralState.THROTTLED:
      return "THROTTLED";
    case GeneralState.MAINTENANCE:
      return "MAINTENANCE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GeneralState");
  }
}

export interface GetStatusRequest {
  /**
   * The status value to get.
   *
   * You can use 'latest' as the id to get the latest status for a tree,
   * i.e. set the name to 'trees/{tree_id}/status/latest'.
   *
   * If you request the 'latest' status and no status updates are in the
   * database (possibly due to the 140 day TTL), a fallback status will
   * be returned with general_state OPEN.  You can tell that the fallback
   * status was returned by checking the name which will be
   * 'trees/{tree_id}/status/fallback', which is otherwise not a valid name.
   *
   * Format: trees/{tree_id}/status/{status_id}
   */
  readonly name: string;
}

/** The Status of a tree for an interval of time. */
export interface Status {
  /**
   * The name of this status.
   * Format: trees/{tree_id}/status/{status_id}
   */
  readonly name: string;
  /**
   * The general state of the tree.  Possible values are open, closed, throttled
   * and maintenance.
   */
  readonly generalState: GeneralState;
  /**
   * The message explaining details about the status.  This may contain HTML,
   * it is the responsibility of the caller to sanitize the HTML before display.
   * Maximum length of 1024 bytes.  Must be a valid UTF-8 string in normalized form
   * C without any non-printable runes.
   */
  readonly message: string;
  /**
   * The email address of the user who added this.  May be empty if
   * the reader does not have permission to see personal data.  Will also be
   * set to 'user' after the user data TTL (of 30 days).
   */
  readonly createUser: string;
  /** The time the status update was made. */
  readonly createTime:
    | string
    | undefined;
  /**
   * Optional. Only applicable when general_state == CLOSED.
   * If this field is set when general_state != CLOSED, it will be ignored.
   * The name of the LUCI builder that caused the tree to close.
   * Format: projects/{project}/buckets/{bucket}/builders/{builder}.
   * This field will be populated by LUCI Notify, when it automatically
   * closes a tree. When a human closes a tree, we do not require this field
   * to be set.
   * Note: If a tree is closed due to multiple builders, only the first failure
   * will be recorded.
   * This field will be exported to BigQuery for analysis.
   */
  readonly closingBuilderName: string;
}

export interface ListStatusRequest {
  /**
   * The parent tree which the status values belongs to.
   * Format: trees/{tree_id}/status
   */
  readonly parent: string;
  /**
   * The maximum number of status values to return. The service may return fewer
   * than this value. If unspecified, at most 50 status values will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListStatus` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListStatus` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
}

export interface ListStatusResponse {
  /** The status values of the tree. */
  readonly status: readonly Status[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface CreateStatusRequest {
  /**
   * The parent tree which the status values belongs to.
   * Format: trees/{tree_id}/status
   */
  readonly parent: string;
  /**
   * The status to create.
   * Only the general state and message fields can be provided, the current date
   * will be used for the date and the RPC caller will be used for the username.
   */
  readonly status: Status | undefined;
}

function createBaseGetStatusRequest(): GetStatusRequest {
  return { name: "" };
}

export const GetStatusRequest: MessageFns<GetStatusRequest> = {
  encode(message: GetStatusRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetStatusRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStatusRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStatusRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetStatusRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetStatusRequest>): GetStatusRequest {
    return GetStatusRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetStatusRequest>): GetStatusRequest {
    const message = createBaseGetStatusRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseStatus(): Status {
  return { name: "", generalState: 0, message: "", createUser: "", createTime: undefined, closingBuilderName: "" };
}

export const Status: MessageFns<Status> = {
  encode(message: Status, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.generalState !== 0) {
      writer.uint32(16).int32(message.generalState);
    }
    if (message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    if (message.createUser !== "") {
      writer.uint32(34).string(message.createUser);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(42).fork()).join();
    }
    if (message.closingBuilderName !== "") {
      writer.uint32(50).string(message.closingBuilderName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Status {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.generalState = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.createUser = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.closingBuilderName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      generalState: isSet(object.generalState) ? generalStateFromJSON(object.generalState) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      createUser: isSet(object.createUser) ? globalThis.String(object.createUser) : "",
      createTime: isSet(object.createTime) ? globalThis.String(object.createTime) : undefined,
      closingBuilderName: isSet(object.closingBuilderName) ? globalThis.String(object.closingBuilderName) : "",
    };
  },

  toJSON(message: Status): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.generalState !== 0) {
      obj.generalState = generalStateToJSON(message.generalState);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.createUser !== "") {
      obj.createUser = message.createUser;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime;
    }
    if (message.closingBuilderName !== "") {
      obj.closingBuilderName = message.closingBuilderName;
    }
    return obj;
  },

  create(base?: DeepPartial<Status>): Status {
    return Status.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Status>): Status {
    const message = createBaseStatus() as any;
    message.name = object.name ?? "";
    message.generalState = object.generalState ?? 0;
    message.message = object.message ?? "";
    message.createUser = object.createUser ?? "";
    message.createTime = object.createTime ?? undefined;
    message.closingBuilderName = object.closingBuilderName ?? "";
    return message;
  },
};

function createBaseListStatusRequest(): ListStatusRequest {
  return { parent: "", pageSize: 0, pageToken: "" };
}

export const ListStatusRequest: MessageFns<ListStatusRequest> = {
  encode(message: ListStatusRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(26).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListStatusRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListStatusRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListStatusRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: ListStatusRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListStatusRequest>): ListStatusRequest {
    return ListStatusRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListStatusRequest>): ListStatusRequest {
    const message = createBaseListStatusRequest() as any;
    message.parent = object.parent ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseListStatusResponse(): ListStatusResponse {
  return { status: [], nextPageToken: "" };
}

export const ListStatusResponse: MessageFns<ListStatusResponse> = {
  encode(message: ListStatusResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.status) {
      Status.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListStatusResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListStatusResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.status.push(Status.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListStatusResponse {
    return {
      status: globalThis.Array.isArray(object?.status) ? object.status.map((e: any) => Status.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListStatusResponse): unknown {
    const obj: any = {};
    if (message.status?.length) {
      obj.status = message.status.map((e) => Status.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListStatusResponse>): ListStatusResponse {
    return ListStatusResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListStatusResponse>): ListStatusResponse {
    const message = createBaseListStatusResponse() as any;
    message.status = object.status?.map((e) => Status.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseCreateStatusRequest(): CreateStatusRequest {
  return { parent: "", status: undefined };
}

export const CreateStatusRequest: MessageFns<CreateStatusRequest> = {
  encode(message: CreateStatusRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.status !== undefined) {
      Status.encode(message.status, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateStatusRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateStatusRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.status = Status.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateStatusRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      status: isSet(object.status) ? Status.fromJSON(object.status) : undefined,
    };
  },

  toJSON(message: CreateStatusRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.status !== undefined) {
      obj.status = Status.toJSON(message.status);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateStatusRequest>): CreateStatusRequest {
    return CreateStatusRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateStatusRequest>): CreateStatusRequest {
    const message = createBaseCreateStatusRequest() as any;
    message.parent = object.parent ?? "";
    message.status = (object.status !== undefined && object.status !== null)
      ? Status.fromPartial(object.status)
      : undefined;
    return message;
  },
};

/**
 * Service to get and set status for LUCI trees.
 *
 * Use of LUCI is subject to the Google [Terms of Service](https://policies.google.com/terms)
 * and [Privacy Policy](https://policies.google.com/privacy).
 */
export interface TreeStatus {
  /** List all status values for a tree in reverse chronological order. */
  ListStatus(request: ListStatusRequest): Promise<ListStatusResponse>;
  /**
   * Get a status for a tree.
   * Use the resource alias 'latest' to get just the current status.
   */
  GetStatus(request: GetStatusRequest): Promise<Status>;
  /** Create a new status update for the tree. */
  CreateStatus(request: CreateStatusRequest): Promise<Status>;
}

export const TreeStatusServiceName = "luci.tree_status.v1.TreeStatus";
export class TreeStatusClientImpl implements TreeStatus {
  static readonly DEFAULT_SERVICE = TreeStatusServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || TreeStatusServiceName;
    this.rpc = rpc;
    this.ListStatus = this.ListStatus.bind(this);
    this.GetStatus = this.GetStatus.bind(this);
    this.CreateStatus = this.CreateStatus.bind(this);
  }
  ListStatus(request: ListStatusRequest): Promise<ListStatusResponse> {
    const data = ListStatusRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListStatus", data);
    return promise.then((data) => ListStatusResponse.fromJSON(data));
  }

  GetStatus(request: GetStatusRequest): Promise<Status> {
    const data = GetStatusRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetStatus", data);
    return promise.then((data) => Status.fromJSON(data));
  }

  CreateStatus(request: CreateStatusRequest): Promise<Status> {
    const data = CreateStatusRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateStatus", data);
    return promise.then((data) => Status.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
